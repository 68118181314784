<template>
  <vis-loader
    :loading="
      building.isFetching ||
      units.isFetching ||
      statuses.isFetching ||
      fields.isFetching ||
      apartmentDatasheetNotes.isFetching ||
      buildingUsers.isFetching
    "
  >
    <vis-sheet>
      <h4>{{ $t('general') }}</h4>
      <building-form :building="building.data!" @submit="onSubmit" />
    </vis-sheet>
    <vis-sheet>
      <h4>{{ $t('apartmentDatasheetNotes') }}</h4>
      <apartment-datasheet-notes-form
        :fields="fields.data!"
        :apartment-datasheet-notes="apartmentDatasheetNotes.data!"
        @submit="onNotesSubmit"
      />
    </vis-sheet>
    <vis-sheet>
      <h4>{{ $t('accessPermissions') }}</h4>
      <user-access-form :users="buildingUsers.data!" @grant="onGrant" @revoke="onRevoke" />
    </vis-sheet>
    <vis-sheet>
      <h4>{{ $t('apartmentChooser') }}</h4>
      <div class="w-100 d-flex align-center justify-center">
        <apartment-chooser
          :project="project.data!"
          :units="units.data!"
          :statuses="statuses.data!"
          :building="building.data!"
        />
      </div>
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useRouteId from '@/composables/useRouteId'
import useBuildingStore from '@/stores/building'
import BuildingForm from '@/components/forms/BuildingForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import ApartmentDatasheetNotesForm from '@/components/forms/ApartmentDatasheetNotesForm.vue'
import UserAccessForm from '@/components/forms/UserAccessForm.vue'
import ApartmentChooser from '@/components/players/ApartmentChooser.vue'
import useFieldStore from '@/stores/field'
import useApartmentDatasheetNoteStore from '@/stores/apartmentDatasheetNote'
import useBuildingPermissionStore from '@/stores/buildingPermission'
import type User from '@/types/user'
import useProjectStore from '@/stores/project'
import useUnitStore from '@/stores/unit'
import useStatusStore from '@/stores/status'

const { project } = useProjectStore()

const { units, listUnits } = useUnitStore()
units.data || listUnits()

const { statuses, listStatuses } = useStatusStore()
statuses.data || listStatuses()

const { fields, listFields } = useFieldStore()
fields.data || listFields()

const { building, listBuildings, readBuilding, updateBuilding } = useBuildingStore()
const onSubmit = useSubmitThenRoute(updateBuilding, { name: 'buildings' }, () => listBuildings())

const { apartmentDatasheetNotes, listApartmentDatasheetNotes, patchApartmentDatasheetNotes } =
  useApartmentDatasheetNoteStore()

const onNotesSubmit = useSubmitThenRoute(patchApartmentDatasheetNotes, { name: 'buildings' }, () =>
  listApartmentDatasheetNotes()
)

const { buildingUsers, listBuildingUsers, grantBuildingPermissions, revokeBuildingPermissions } =
  useBuildingPermissionStore()

const onGrant = async (email: string) => {
  await grantBuildingPermissions(email)
  listBuildingUsers()
}

const onRevoke = async (user: User) => {
  await revokeBuildingPermissions(user.email)
  listBuildingUsers()
}

const buildingId = useRouteId('buildingId')
readBuilding(buildingId).then(() => {
  listApartmentDatasheetNotes()
  listBuildingUsers()
})
</script>
