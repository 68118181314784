import { createI18n } from 'vue-i18n'
import en from '../locales/en.json'
import sv from '../locales/sv.json'
import nb from '../locales/nb.json'
import { en as enVuetify, sv as svVuetify, no as nbVuetify } from 'vuetify/locale'

const i18n = createI18n({
  legacy: false,
  messages: {
    en: {
      ...en,
      $vuetify: {
        ...enVuetify
      }
    },
    sv: {
      ...sv,
      $vuetify: {
        ...svVuetify
      }
    },
    nb: {
      ...nb,
      $vuetify: {
        ...nbVuetify
      }
    }
  },
  locale: 'en',
  fallbackLocale: 'en',
  missing(locale, key) {
    console.warn(`vuex-i18n :: Key '${key}' not found for locale '${locale}'`)
    if (import.meta.env.MODE === 'development') {
      fetch('/i18n', {
        method: 'POST',
        body: key
      })
    }
  }
})

export default i18n
